import Head from 'next/head'
import Link from 'next/link'
import NotificationsTray from '../notifications-tray'
import { Layout, notification, Dropdown, Tooltip, Button } from 'antd'
import { useContext, useEffect, useMemo, useState } from 'react'
import styles from './Page.module.css'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { setApp, signOut } from '../../store/auth/actions'
import { apps, claimsRoles, industries, InventoryEditions, paths, Apps as App, LocationTypes, PREVIOUSLY_USED_ORDER_SOURCE_KEY, BillingInvoiceStatus, BillingPaymentStatus, purchaseOrderSubStatuses, industryGroup, PREVIOUSLY_SELECTED_PICK_UP_LOCATION } from '../../utils/constants'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { capitalizeWord, isNotEmpty } from '../../utils'
import Sidebar from '../sidebar'
import { usePage } from '../../hooks/usePage'
import EnterpriseInventoryFilter from '../enterprise-inventory-filter'
import BasicInventoryFilter from '../basic-inventory-filter'
import StandardInventoryFilter from '../standard-inventory-filter'
import Apps from '../apps'
import EcommerceSalesOrderFilter from '../e-commerce-sales-order-filter'
import DeliveryPlanFilter from '../delivery-plan-filter'
import GlobalProductFilter from '../global-product-filter'
import ECommerceCustomerFilter from '../e-commerce-customer-filter'
import GeneralCustomerFilter from '../general-customer-filter'
import VehicleFilter from '../vehicle-filter'
import UserFilter from '../user-filter'
import ReportFilter from '../report-filter'
import ProfileMenu from '../profile-menu'
import { globalSearchLocalStorageKeys } from '../../utils/constants'
import SearchAndFilter from '../search-and-filter'
import { SalesOrders } from '../../services/api/sales-orders'
import GeneralSalesOrderFilter from '../general-sales-order-filter'
import { DeliveryPlans } from '../../services/api/delivery-plans'
import { Products } from '../../services/api/products'
import { Distributors } from '../../services/api/distributors'
import { Inventory } from '../../services/api/inventory'
import { Vehicles } from '../../services/api/vehicles'
import { Users } from '../../services/api/users'
import { Reports } from '../../services/api/reports'
import { Companies } from '../../services/api/companies'
import CompanyFilter from '../company-filter'
import { Locations } from '../../services/api/locations'
import LocationFilter from '../location-filter'
import useSearchParams from '../../hooks/useSearchParams'
import { DeliveryPartners } from '../../services/api/delivery-partner'
import DeliveryPartnerFilter from '../delivery-partner-filter'
import { Expenses } from '../../services/api/expenses'
import ExpenseFilter from '../expense-filter'
import { Vendors } from '../../services/api/vendors'
import VendorFilter from '../vendor-filter'
import { PurchaseOrders } from '../../services/api/purchase-orders'
import PurchaseOrderFilter from '../purchase-order-filter'
import { Payments } from '../../services/api/payments'
import BillingInvoiceFilter from '../billing-invoice-filter'
import BillingPaymentFilter from '../billing-payment-filter'
import AdvancedInventoryLogFilter from '../advanced-inventory-log-filter'
import AdvancedOnHandStockFilter from '../advanced-on-hand-stock-filter'
import NotificationBar from '../notification-bar'
import { NotificationBarContext } from '../../contexts/NotificationBar'
import { Presence } from '../../services/api/firebase'
import { selectSalesOrderIds } from '../../store/sales-orders/actions'
import getFirebaseApp from '../../config/initFirebase'
import { getAuth } from 'firebase/auth'
import withAuth, { AuthAction } from '../../config/withAuth'

const app = getFirebaseApp()
const auth = getAuth(app)

const { Header, Content } = Layout

const omsMenuItems = [
	{
		icon: '/img/menu/dashboard.svg#icon',
		title: 'Dashboard',
		path: paths.HOME
	},
	{
		icon: '/img/menu/plot.svg#icon',
		title: 'Forecast',
		path: paths.FORECAST
	},
	{
		icon: '/img/menu/light-bulb.svg#icon',
		title: 'Insights',
		path: paths.INSIGHTS
	},
	{
		icon: '/img/menu/route-line.svg#icon',
		title: 'Delivery Plan',
		path: paths.DELIVERY_PLANS
	},
	{
		icon: '/img/menu/shopping-bag.svg#icon',
		title: 'Orders',
		path: paths.SALES_ORDERS
	},
	{
		icon: '/img/menu/shopping-cart.svg#icon',
		title: 'Purchase',
		path: paths.PURCHASE_ORDERS
	},
	{
		icon: '/img/menu/inventory.svg#icon',
		title: 'Inventory',
		path: paths.INVENTORY_PRODUCTS
	},
	{
		icon: '/img/menu/taka.svg#icon',
		title: 'Generate Labels',
		path: paths.GENERATE_LABELS
	},
	{
		icon: '/img/menu/cube.svg#icon',
		title: 'Products',
		path: paths.PRODUCTS
	},
	{
		icon: '/img/menu/people.svg#icon',
		title: 'Customers',
		path: paths.CUSTOMERS
	},
	{
		icon: '/img/menu/person-star.svg#icon',
		title: 'Vendors',
		path: paths.VENDORS
	},
	{
		icon: '/img/menu/transport-vendor.svg#icon',
		title: 'Delivery Partners',
		path: paths.DELIVERY_PARTNERS
	},
	{
		icon: '/img/menu/car.svg#icon',
		title: 'Fleet',
		path: paths.FLEET
	},
	{
		icon: '/img/menu/transport-vendor.svg#icon',
		title: 'Transport Vendors',
		path: paths.TRANSPORT_VENDORS
	},
	{
		icon: '/img/menu/transport-quotation.svg#icon',
		title: 'Transport Quotations',
		path: paths.TRANSPORT_QUOTATIONS
	},
	{
		icon: '/img/menu/expense.svg#icon',
		title: 'Expenses',
		path: paths.EXPENSES
	},
	{
		icon: '/img/menu/pin.svg#icon',
		title: 'Locations',
		path: paths.LOCATIONS
	},
	{
		icon: '/img/menu/file-text.svg#icon',
		title: 'Reports',
		path: paths.REPORTS
	},
	{
		icon: '/img/menu/trash.svg#icon',
		title: 'Recycle Bin',
		path: paths.RECYCLE_BIN
	},
	{
		icon: '/img/menu/lock.svg#icon',
		title: 'Access',
		children: [
			{
				title: 'Permission Groups',
				path: paths.PERMISSIONS
			},
			{
				title: 'Users',
				path: paths.USERS
			}
		]
	}
]

const wmsMenuItems = [
	{
		icon: '/img/menu/configuration.svg#icon',
		title: 'Configuration',
		children: [
			{
				title: 'Warehouse',
				path: paths.WAREHOUSE
			}
		]
	}
]

const adminMenuItems = [
	{
		icon: '/img/menu/dashboard.svg#icon',
		title: 'Dashboard',
		path: paths.ADMIN_DASHBOARD
	},
	{
		icon: '/img/store.svg#icon',
		title: 'Companies',
		path: `${paths.COMPANIES}?industryGroup=${industryGroup.E_COMMERCE}`
	},
	{
		icon: '/img/user-account.svg#icon',
		title: 'Registered Users',
		path: paths.USERS
	},
	{
		icon: '/img/bad-data.svg#icon',
		title: 'Bad Data',
		path: paths.BAD_DATA
	},
	{
		icon: '/img/billing-and-payments.svg#icon',
		title: 'Billing & Payments',
		path: paths.BILLING_AND_PAYMENTS
	},
	{
		icon: '/img/customers-transactions.svg#icon',
		title: 'Customers Transactions',
		path: paths.CUSTOMERS_TRANSACTIONS
	},
	{
		icon: '/img/menu/lock.svg#icon',
		title: 'Access',
		children: [
			{
				title: 'Permission Groups',
				path: `${paths.PERMISSIONS}/admin`
			},
			{
				title: 'Users',
				path: `${paths.USERS}/admin`
			}
		]
	}
]

const omsNavLinks = [
	{
		title: 'Dashboard',
		path: paths.DASHBOARD
	},
	{
		title: 'Orders',
		path: paths.SALES_ORDERS
	}
]

const wmsNavLinks = [
	{
		title: 'Dashboard',
		path: paths.DASHBOARD
	},
	{
		title: 'Warehouse',
		path: paths.WAREHOUSE
	}
]

const tmsNavLinks = [
	{
		title: 'Dashboard',
		path: paths.DASHBOARD
	},
	{
		title: 'Fleet',
		path: paths.FLEET
	}
]

const dmsNavLinks = [
	{
		title: 'Dashboard',
		path: paths.DASHBOARD
	},
	{
		title: 'Delivery Plans',
		path: paths.DELIVERY_PLANS
	}
]

const Page = ({ children, parentTitle, childTitle, fullPage, parentLink, className }) => {
	const router = useRouter()
	const { pathname, query } = router
	const { tab } = query
	const { searchParams: { statuses, status }, clearSearchParams } = useSearchParams()
	const dispatch = useDispatch()
	const { pageTitle, pageChildTitle, backPageLink } = usePage()
	const { role, companyDetails, permissions, app, pageName, companyId, authUser } = useSelector(state => state.authReducer)
	const [logoUrl, setLogoUrl] = useLocalStorage('logoUrl', '')
	const { unreadCount, newInsights } = useSelector(state => state.notificationsReducer)
	const [rerender, setRerender] = useState(false)
	const isNotificationBarVisible = useContext(NotificationBarContext)
	const isECommerce = useMemo(() => {
		return companyDetails?.industry === industries.E_COMMERCE
	}, [companyDetails])

	const activeSearchAndFilter = useMemo(() => {
		if (!role || pathname.includes('[id]')) {
			return null
		}
		if (role === claimsRoles.ADMIN) {
			if (pathname.includes(paths.USERS)) {
				return (
					<SearchAndFilter
						key={1}
						localStorageKey={globalSearchLocalStorageKeys.NUPORT_RECENT_USERS_SEARCH_RESULT}
						tooltipTitle='Search User Name, Email, or Phone Number'
						searchErrorMessage='Unable to search users'
						searchMethod={Users.index}
						getResultHref={({ id }) => `${paths.USERS}/${id}`}
						getResultLabel={({ name }) => name}
						FilterDropdownOverlay={UserFilter}
					/>
				)
			} else if (pathname === paths.COMPANIES) {
				return (
					<SearchAndFilter
						key={2}
						localStorageKey={globalSearchLocalStorageKeys.NUPORT_RECENT_COMPANIES_SEARCH_RESULT}
						tooltipTitle='Search Company Name, Company ID or User Email'
						searchErrorMessage='Unable to search companies'
						searchMethod={Companies.index}
						getResultHref={({ id }) => permissions.viewCompanyDetails ? `${paths.COMPANIES}/${id}` : null}
						getResultLabel={({ name }) => name}
						FilterDropdownOverlay={CompanyFilter}
					/>
				)
			} else if (pathname.includes(paths.BILLING_AND_PAYMENTS)) {
				if (Object.keys(query).length === 0 || query?.module === 'billing') {
					return (
						<SearchAndFilter
							key={3}
							localStorageKey={globalSearchLocalStorageKeys.NUPORT_RECENT_BILLING_INVOICE_SEARCH_RESULT}
							tooltipTitle='Search Invoices by Invoice Number, Reference ID, and Company Name'
							searchErrorMessage='Unable to search invoices'
							searchMethod={Payments.billingInvoiceIndex}
							getResultHref={({ id }) => `${paths.BILLING_AND_PAYMENTS}/invoice/${id}`}
							getResultLabel={({ stripeInvoiceNumber, status }) => {
								return (
									<div className={styles.invoiceResultContainer}>
										<span>{stripeInvoiceNumber}</span>
										<span
											className={
												`
													${status === BillingInvoiceStatus.PAID ? styles.invoicePaidStatus : ''}
													${status === BillingInvoiceStatus.UNPAID ? styles.invoiceUnpaidStatus : ''}
													${status === BillingInvoiceStatus.VOID ? styles.invoiceVoidStatus : ''}
												`
											}
										>
											{capitalizeWord(status)}
										</span>
									</div>
								)
							}}
							FilterDropdownOverlay={BillingInvoiceFilter}
						/>
					)
				}
				if (query?.module === 'payment') {
					return (
						<SearchAndFilter
							key={4}
							localStorageKey={globalSearchLocalStorageKeys.NUPORT_RECENT_BILLING_PAYMENT_SEARCH_RESULT}
							tooltipTitle='Search Payment ID, Reference ID, Invoice Number and Company Name'
							searchErrorMessage='Unable to search payments'
							searchMethod={Payments.index}
							getResultHref={({ id }) => `${paths.BILLING_AND_PAYMENTS}/payment/${id}`}
							getResultLabel={({ invoiceNumber, company, status }) => {
								return (
									<div className={styles.paymentResultContainer}>
										<span>{`${invoiceNumber} - ${company.name}`}</span>
										<span
											className={
												`
													${status === BillingPaymentStatus.PAID ? styles.paymentPaidStatus : ''}
													${status === BillingPaymentStatus.RECONCILED ? styles.paymentReconciledStatus : ''}
													${status === BillingPaymentStatus.VOID ? styles.paymentVoidStatus : ''}
												`
											}
										>
											{capitalizeWord(status)}
										</span>
									</div>
								)
							}}
							FilterDropdownOverlay={BillingPaymentFilter}
						/>
					)
				}
			} else {
				return null
			}
		}
		if (pathname.includes(paths.SALES_ORDERS)) {
			const redirectToRespectiveTab = ({ statuses, status }) => {
				if (status) {
					return router.push(`${paths.SALES_ORDERS}?status=${status}`)
				} else if (statuses && statuses.length === 1) {
					router.push(`${paths.SALES_ORDERS}?status=${statuses[0]}`)
				} else {
					router.push(`${paths.SALES_ORDERS}?status=all`)
				}
			}

			if (companyDetails?.industry === industries.E_COMMERCE) {
				return (
					<SearchAndFilter
						key={3}
						localStorageKey={globalSearchLocalStorageKeys.NUPORT_RECENT_SALES_ORDERS_SEARCH_RESULT}
						tooltipTitle='Search order ID, Customer Name, Contact number, Email, Product name, Product SKU, Website order ID, Delivery Partner Order ID'
						searchErrorMessage='Unable to search orders'
						searchMethod={SalesOrders.index}
						getResultHref={({ id }) => `${paths.SALES_ORDERS}/${id}`}
						getResultLabel={({ internalId, distributor }) => {
							return (
								<div>
								    <div>{internalId}</div>
									{
										distributor &&
                                        <>
                                        	<div className={styles.filterLabel}>{distributor.name}</div>
                                        	<div className={styles.filterLabel}>{distributor.phone}</div>
                                        </>
									}
								</div>
							)
						}}
						FilterDropdownOverlay={EcommerceSalesOrderFilter}
						onSearch={() => redirectToRespectiveTab({ statuses })}
						onFilter={redirectToRespectiveTab}
					/>
				)
			} else {
				return (
					<SearchAndFilter
						key={4}
						localStorageKey={globalSearchLocalStorageKeys.NUPORT_RECENT_SALES_ORDERS_SEARCH_RESULT}
						tooltipTitle='Search Order Number, Customer Name, Customer Phone'
						searchErrorMessage='Unable to search orders'
						searchMethod={SalesOrders.index}
						getResultHref={({ id }) => `${paths.SALES_ORDERS}/${id}`}
						getResultLabel={({ internalId }) => internalId}
						FilterDropdownOverlay={GeneralSalesOrderFilter}
						onSearch={() => redirectToRespectiveTab({ status })}
						onFilter={redirectToRespectiveTab}
					/>
				)
			}
		} else if (pathname.includes(paths.DELIVERY_PLANS)) {
			if (!permissions.viewDeliveryPlans || companyDetails?.isDeliveryPlanEnabled === false) {
				return null
			}
			return (
				<SearchAndFilter
					key={5}
					localStorageKey={globalSearchLocalStorageKeys.NUPORT_RECENT_DELIVERY_PLANS_SEARCH_RESULT}
					tooltipTitle='Search Delivery Plan Numbers, Customer Name'
					searchErrorMessage='Unable to search delivery plans'
					searchMethod={DeliveryPlans.index}
					getResultHref={({ id }) => `${paths.DELIVERY_PLANS}/${id}`}
					getResultLabel={({ shortId }) => `DP-${shortId}`}
					FilterDropdownOverlay={DeliveryPlanFilter}
				/>
			)
		} else if (pathname.includes(paths.PRODUCTS)) {
			return (
				<SearchAndFilter
					key={6}
					localStorageKey={globalSearchLocalStorageKeys.NUPORT_RECENT_PRODUCTS_SEARCH_RESULT}
					tooltipTitle='Search Product Name or SKU'
					searchErrorMessage='Unable to search products'
					searchMethod={params => Products.index({ ...params, all: true })}
					getDefaultParams={() => ({ all: true })}
					isSearchAppliedPredicate={(params) => {
						if (Object.keys(params).length === 2 && params.all === true && !params.searchTerm) {
							return false
						} else {
							return true
						}
					}}
					getResultHref={({ id }) => `${paths.PRODUCTS}/${id}`}
					getResultLabel={({ name }) => name}
					FilterDropdownOverlay={GlobalProductFilter}
				/>
			)
		} else if (pathname.includes(paths.CUSTOMERS)) {
			if (Object.keys(query).length === 0 || (query.view !== 'map') && query.view !== 'graph') {
				if (companyDetails?.industry === industries.E_COMMERCE) {
					return (
						<SearchAndFilter
							key={7}
							localStorageKey={globalSearchLocalStorageKeys.NUPORT_RECENT_CUSTOMERS_SEARCH_RESULT}
							tooltipTitle='Search Customer Name, Customer Phone, Customer ID, or Customer Email'
							searchErrorMessage='Unable to search customers'
							searchMethod={Distributors.index}
							getResultHref={({ id }) => `${paths.CUSTOMERS}/${id}`}
							getResultLabel={({ name }) => name}
							FilterDropdownOverlay={ECommerceCustomerFilter}
						/>
					)
				} else {
					return (
						<SearchAndFilter
							key={8}
							localStorageKey={globalSearchLocalStorageKeys.NUPORT_RECENT_CUSTOMERS_SEARCH_RESULT}
							tooltipTitle='Search Customer Name, Customer Phone, or Customer ID'
							searchErrorMessage='Unable to search customers'
							searchMethod={Distributors.index}
							getResultHref={({ id }) => `${paths.CUSTOMERS}/${id}`}
							getResultLabel={({ name }) => name}
							FilterDropdownOverlay={GeneralCustomerFilter}
						/>
					)
				}
			} else {
				return null
			}
		} else if (pathname.includes(paths.INVENTORY_PRODUCTS)) {
			switch (companyDetails?.inventoryEdition) {
				case InventoryEditions.ENTERPRISE: {
					return (
						<SearchAndFilter
							key={9}
							localStorageKey={globalSearchLocalStorageKeys.NUPORT_RECENT_INVENTORY_SEARCH_RESULT}
							tooltipTitle='Search by Name, SKU, Serial Number, Pallet Number or Pack Code'
							searchErrorMessage='Unable to search inventory'
							searchMethod={Inventory.indexInventoryProducts}
							getResultHref={({ inventoryItem: { productId } }) => `${paths.PRODUCTS}/${productId}`}
							getResultLabel={({ inventoryItem: { product: { name } } }) => name}
							FilterDropdownOverlay={EnterpriseInventoryFilter}
						/>
					)
				}
				case InventoryEditions.STANDARD: {
					return (
						<SearchAndFilter
							key={10}
							localStorageKey={globalSearchLocalStorageKeys.NUPORT_RECENT_INVENTORY_SEARCH_RESULT}
							tooltipTitle='Search Product ID, Product Name, SKU'
							searchErrorMessage='Unable to search product'
							searchMethod={Products.index}
							getResultHref={({ id }) => `${paths.PRODUCTS}/${id}`}
							getResultLabel={({ name }) => name}
							FilterDropdownOverlay={StandardInventoryFilter}
						/>
					)
				}
				case InventoryEditions.BASIC: {
					return (
						<SearchAndFilter
							key={11}
							localStorageKey={globalSearchLocalStorageKeys.NUPORT_RECENT_INVENTORY_SEARCH_RESULT}
							tooltipTitle='Search Product ID, Product Name, SKU'
							searchErrorMessage='Unable to search inventory'
							searchMethod={Products.index}
							getResultHref={({ id }) => `${paths.PRODUCTS}/${id}`}
							getResultLabel={({ name }) => name}
							FilterDropdownOverlay={BasicInventoryFilter}
						/>
					)
				}
				case InventoryEditions.ADVANCED: {
					return (
						<>
							{
								tab === 'logs' &&
								<SearchAndFilter
									key={12}
									localStorageKey={globalSearchLocalStorageKeys.NUPORT_RECENT_INVENTORY_SEARCH_RESULT}
									tooltipTitle='Search SKU Code, SKU Name, Barcode, Batch ID'
									searchErrorMessage='Unable to search inventory logs'
									searchMethod={Inventory.fetchInventoryLogs}
									getResultHref={({ productId }) => `${paths.PRODUCTS}/${productId}`}
									getResultLabel={({ product }) => product.name}
									FilterDropdownOverlay={AdvancedInventoryLogFilter}
								/>
							}
							{
								tab === 'advancedOnHandStock' &&
								<SearchAndFilter
									key={13}
									localStorageKey={globalSearchLocalStorageKeys.NUPORT_RECENT_INVENTORY_SEARCH_RESULT}
									tooltipTitle='Search SKU Code, SKU Name, Barcode, Batch ID'
									searchErrorMessage='Unable to search on hand stocks'
									searchMethod={(params) => Inventory.fetchInventoryBatchItems({ ...params, excludeShortageQuantity: true })}
									getResultHref={({ productId }) => `${paths.PRODUCTS}/${productId}`}
									getResultLabel={({ product, batchNumber }) => `${batchNumber} (${product.name})`}
									FilterDropdownOverlay={AdvancedOnHandStockFilter}
								/>
							}
						</>
					)
				}
				default:
					return null
			}
		} else if (pathname.includes(paths.FLEET)) {
			if (!permissions.viewFleet || companyDetails?.isFleetEnabled === false) {
				return null
			}
			if (Object.keys(query).length === 0 || (query.view !== 'map')) {
				return (
					<SearchAndFilter
						key={12}
						localStorageKey={globalSearchLocalStorageKeys.NUPORT_RECENT_VEHICLES_SEARCH_RESULT}
						tooltipTitle='Search Vehicle ID, Brand, Model'
						searchErrorMessage='Unable to search vehicles'
						searchMethod={Vehicles.index}
						getResultHref={({ id }) => `${paths.FLEET}/${id}`}
						getResultLabel={({ plateNumber }) => plateNumber}
						FilterDropdownOverlay={VehicleFilter}
					/>
				)
			} else {
				return null
			}
		} else if (pathname.includes(paths.USERS)) {
			return (
				<SearchAndFilter
					key={13}
					localStorageKey={globalSearchLocalStorageKeys.NUPORT_RECENT_USERS_SEARCH_RESULT}
					tooltipTitle='Search User Name, Email, or Phone Number'
					searchErrorMessage='Unable to search users'
					searchMethod={Users.index}
					getResultHref={({ id }) => `${paths.USERS}/${id}`}
					getResultLabel={({ name }) => name}
					FilterDropdownOverlay={UserFilter}
				/>
			)
		} else if (pathname.includes(paths.REPORTS)) {
			return (
				<SearchAndFilter
					key={14}
					localStorageKey={globalSearchLocalStorageKeys.NUPORT_RECENT_REPORTS_SEARCH_RESULT}
					tooltipTitle='Search Report ID'
					searchErrorMessage='Unable to search reports'
					searchMethod={Reports.index}
					getResultHref={({ id }) => `${paths.REPORTS}/${id}`}
					getResultLabel={({ shortId }) => `R-${shortId}`}
					FilterDropdownOverlay={ReportFilter}
				/>
			)
		} else if (pathname.includes(paths.LOCATIONS)) {
			return (
				<SearchAndFilter
					key={15}
					localStorageKey={globalSearchLocalStorageKeys.NUPORT_RECENT_LOCATIONS_SEARCH_RESULT}
					tooltipTitle='Search Address, Label, Internal ID'
					searchErrorMessage='Unable to search locations'
					searchMethod={Locations.index}
					getResultHref={({ id, type }) => [LocationTypes.WAREHOUSE, LocationTypes.FACTORY].includes(type) ? `${paths.WAREHOUSES}/${id}` : undefined}
					getResultLabel={({ label, address }) => label || address}
					FilterDropdownOverlay={LocationFilter}
				/>
			)
		} else if (pathname.includes(paths.DELIVERY_PARTNERS)) {
			return (
				<SearchAndFilter
					key={16}
					localStorageKey={globalSearchLocalStorageKeys.NUPORT_RECENT_DELIVERY_PARTNERS_SEARCH_RESULT}
					tooltipTitle='Search Partner ID, Partner Name, Phone Number'
					searchErrorMessage='Unable to search delivery partners'
					searchMethod={DeliveryPartners.index}
					getResultHref={({ id }) => `${paths.DELIVERY_PARTNERS}/${id}`}
					getResultLabel={({ name }) => name}
					FilterDropdownOverlay={DeliveryPartnerFilter}
				/>
			)
		} else if (pathname.includes(paths.EXPENSES)) {
			return (
				<SearchAndFilter
					key={17}
					localStorageKey={globalSearchLocalStorageKeys.NUPORT_RECENT_EXPENSES_SEARCH_RESULT}
					tooltipTitle='Search Expenses ID'
					searchErrorMessage='Unable to search expenses'
					searchMethod={Expenses.index}
					getResultHref={({ id }) => `${paths.EXPENSES}/${id}`}
					getResultLabel={({ internalId }) => internalId}
					FilterDropdownOverlay={ExpenseFilter}
				/>
			)
		} else if (pathname.includes(paths.VENDORS)) {
			return (
				<SearchAndFilter
					key={18}
					localStorageKey={globalSearchLocalStorageKeys.NUPORT_RECENT_VENDORS_SEARCH_RESULT}
					tooltipTitle='Search Vendor ID, Vendor Name, Vendor Phone'
					searchErrorMessage='Unable to search vendors'
					searchMethod={Vendors.index}
					getResultHref={({ id }) => `${paths.VENDORS}/${id}`}
					getResultLabel={({ internalId, shortId }) => internalId || `V-${shortId}`}
					FilterDropdownOverlay={VendorFilter}
				/>
			)
		} else if (pathname.includes(paths.PURCHASE_ORDERS)) {
			const redirectToRespectiveTab = ({ statuses, subStatuses }) => {
				if (subStatuses?.length > 0 && !statuses?.length) {
					const parentStatusesSet = new Set()
					for (const subStatus of subStatuses) {
						const parentStatus = purchaseOrderSubStatuses
							.find((purchaseOrderSubStatus) => purchaseOrderSubStatus.subStatus === subStatus)
							.status
						parentStatusesSet.add(parentStatus)
					}
					const parentStatuses = [...parentStatusesSet]

					if (subStatuses.length > 1) {
						router.push(`${paths.PURCHASE_ORDERS}?status=${parentStatuses.length > 1 ? 'all' : parentStatuses[0]}`)
					} else {
						router.push(`${paths.PURCHASE_ORDERS}?status=${parentStatuses[0]}&subStatus=${subStatuses[0]}`)
					}
				} else if (!statuses || statuses?.length > 1) {
					router.push(`${paths.PURCHASE_ORDERS}?status=all`)
				} else if (statuses?.length === 1) {
					if (subStatuses?.length > 1 || !subStatuses?.length) {
						router.push(`${paths.PURCHASE_ORDERS}?status=${statuses[0]}`)
					} else {
						router.push(`${paths.PURCHASE_ORDERS}?status=${statuses[0]}&subStatus=${subStatuses[0]}`)
					}
				}
			}

			return (
				<SearchAndFilter
					key={19}
					localStorageKey={globalSearchLocalStorageKeys.NUPORT_RECENT_PURCHASE_ORDERS_SEARCH_RESULT}
					tooltipTitle='Search Purchase Order ID/Number, Vendor Name'
					searchErrorMessage='Unable to search purchase orders'
					searchMethod={PurchaseOrders.index}
					getResultHref={({ id }) => `${paths.PURCHASE_ORDERS}/${id}`}
					getResultLabel={({ internalId, shortId }) => internalId || `PO-${shortId}`}
					FilterDropdownOverlay={PurchaseOrderFilter}
					onFilter={redirectToRespectiveTab}
				/>
			)
		}
		return null
	}, [
		pathname,
		query,
		role,
		statuses,
		status,
		companyDetails,
		permissions
	])

	const modifiedNavLinks = useMemo(() => {
		let navLinks = []
		const currentApp = localStorage.getItem('app') || app
		switch (currentApp) {
			case App.OMS:
				navLinks = omsNavLinks
				break
			case App.WMS:
				navLinks = wmsNavLinks
				break
			case App.TMS:
				navLinks = tmsNavLinks
				break
			case App.DMS:
				navLinks = dmsNavLinks
				break
		}
		return navLinks.filter(navLink => {
			if (navLink.path === paths.DASHBOARD && !permissions.viewDashboardAnalytics) {
				return false
			}
			if (navLink.path === paths.SALES_ORDERS && !permissions.viewOrders) {
				return false
			}
			if (navLink.path === paths.FLEET && !permissions.viewFleet) {
				return false
			}
			if (navLink.path === paths.DELIVERY_PLANS && !permissions.viewDeliveryPlans) {
				return false
			}
			return true
		})
	}, [permissions, app, rerender])

	const modifiedMenuItems = useMemo(() => {
		if (!role) {
			return []
		}
		if (role === claimsRoles.ADMIN) {
			return window.structuredClone(adminMenuItems)
				.filter(menuItem => {
					if (menuItem.path === paths.DASHBOARD && !permissions.viewDashboardAnalytics) {
						return false
					}
					if (menuItem.path === paths.COMPANIES && !permissions.viewCompanies) {
						return false
					}
					if (menuItem.path === paths.USERS && !permissions.viewUsers) {
						return false
					}
					if (menuItem.path === paths.BILLING_AND_PAYMENTS && !permissions.viewBillingPayments) {
						return false
					}
					if (menuItem.path === paths.BAD_DATA && !permissions.viewBadData) {
						return false
					}
					if (menuItem.children) {
						menuItem.children = menuItem.children.filter(subMenuItem => {
							if (subMenuItem.path === `${paths.USERS}/admin` && !permissions.viewAdminUsers) {
								return false
							}
							return true
						})
					}
					return true
				})
		} else {
			let userMenuItems = omsMenuItems
			const currentApp = localStorage.getItem('app') || app
			switch (currentApp) {
				case App.OMS:
					userMenuItems = omsMenuItems
					break
				case App.WMS:
					userMenuItems = wmsMenuItems
					break
			}
			return userMenuItems
				.filter(menuItem => {
					if (menuItem.path === paths.DASHBOARD && !permissions.viewDashboardAnalytics) {
						return false
					}
					if (menuItem.path === paths.FORECAST && (companyDetails?.isForecastEnabled === false || !permissions.viewForecasts)) {
						return false
					}
					if (menuItem.path === paths.INSIGHTS && (companyDetails?.isInsightsEnabled === false || !permissions.viewInsights)) {
						return false
					}
					if (menuItem.path === paths.DELIVERY_PLANS && (companyDetails?.isDeliveryPlanEnabled === false || !permissions.viewDeliveryPlans || companyDetails?.industry === industries.E_COMMERCE)) {
						return false
					}
					if (menuItem.path === paths.SALES_ORDERS && !permissions.viewOrders) {
						return false
					}
					if (menuItem.path === paths.CUSTOMERS && !permissions.viewDistributors) {
						return false
					}
					if (menuItem.path === paths.PRODUCTS && !permissions.viewProducts) {
						return false
					}
					if (menuItem.path === paths.LOCATIONS && !permissions.viewLocations) {
						return false
					}
					if (menuItem.path === paths.REPORTS && !permissions.viewReports) {
						return false
					}
					if (menuItem.path === paths.USERS && !permissions.viewUsers) {
						return false
					}
					if (menuItem.path === paths.PURCHASE_ORDERS && (companyDetails?.isPurchaseOrderEnabled === false || !permissions.viewPurchaseOrders)) {
						return false
					}
					if (menuItem.path === paths.FLEET && (companyDetails?.isFleetEnabled === false || !permissions.viewFleet || companyDetails?.industry === industries.E_COMMERCE)) {
						return false
					}
					if (menuItem.path === paths.INVENTORY_PRODUCTS && (companyDetails?.isInventoryEnabled === false || !permissions.viewInventory)) {
						return false
					}
					if (menuItem.path === paths.VENDORS && (companyDetails?.isVendorEnabled === false || !permissions.viewVendors)) {
						return false
					}
					if (menuItem.path === paths.GENERATE_LABELS && (permissions.viewInventory || !permissions.createLabels)) {
						return false
					}
					if (menuItem.path === paths.TRANSPORT_VENDORS && (companyDetails?.industry === industries.E_COMMERCE || companyDetails?.isTransportVendorsEnabled === false)) {
						return false
					}
					if (menuItem.path === paths.TRANSPORT_QUOTATIONS && (companyDetails?.industry === industries.E_COMMERCE || companyDetails?.isTransportQuotationsEnabled === false)) {
						return false
					}
					if (menuItem.path === paths.DELIVERY_PARTNERS) {
						if (companyDetails?.industry === industries.E_COMMERCE) {
							if (!permissions.viewDeliveryPartners) {
								return false
							}
						} else {
							return false
						}
					}
					if (menuItem.path === paths.RECYCLE_BIN && !permissions.deleteLocations) {
						return false
					}
					if (menuItem.path === paths.EXPENSES && (companyDetails?.isExpensesEnabled === false || !permissions.viewExpenses)) {
						return false
					}
					if (menuItem.children) {
						menuItem.children = menuItem.children.filter(subMenuItem => {
							if (subMenuItem.path === paths.USERS && !permissions.viewUsers) {
								return false
							}
							return true
						})
					}
					return true
				})
		}
	}, [permissions, companyDetails, role, app, rerender])

	const defaultApp = useMemo(() => {
		const currentApp = localStorage.getItem('app') || app
		return apps.find(app => app.value === currentApp)
	}, [companyDetails, app, rerender])

	useEffect(() => {
		if (companyDetails) {
			setLogoUrl(companyDetails.logoUrl)
		}
	}, [companyDetails])

	const onAppSelected = async selectedApp => {
		await dispatch(setApp(selectedApp))
		switch (selectedApp) {
			case App.OMS:
				router.push(paths.SALES_ORDERS)
				break
			case App.WMS:
				router.push(paths.WAREHOUSE)
				break
			case App.TMS:
				router.push(paths.FLEET)
				break
			case App.DMS:
				router.push(paths.DELIVERY_PLANS)
		}
		// Used to trigger a re-render of the page since redux will not trigger a re-render
		// if `app` is the same as the previous value.
		setRerender(!rerender)
	}

	const logout = async () => {
		try {
			if (window.fcWidget) {
				const { fcWidget } = window
				// Can potentially block the logout process, therefore we are not awaiting the promise.
				fcWidget.user.clear()
					.then(() => {
						// No-op
					})
					.catch(e => console.error(e))
			}
			if (window.mixpanel) {
				try {
					const { mixpanel } = window
					mixpanel.reset()
				} catch (e) {
					// No-op
				}
			}
			if (companyId && pageName && authUser) {
				await Presence.removePresence(companyId, pageName, authUser.uid)
			}
			await auth.signOut()
			localStorage.removeItem('logoUrl')
			localStorage.removeItem('nuportRecentProductSearch')
			localStorage.removeItem(PREVIOUSLY_USED_ORDER_SOURCE_KEY)
			localStorage.removeItem(PREVIOUSLY_SELECTED_PICK_UP_LOCATION)
			localStorage.removeItem('app')
			Object.values(globalSearchLocalStorageKeys).forEach((key) => localStorage.removeItem(key))
			dispatch(signOut())
		} catch (e) {
			notification.open({
				message: 'Logout Error',
				description: e.message,
				placement: 'bottomLeft'
			})
		}
	}

	return (
		<div className={className}>
			<Head>
				<title>Nuport</title>
				<link rel='icon' href='/favicon.ico' />
			</Head>

			{/* Navbar contents */}
			<Layout className={styles.layout}>
				<NotificationBar />
				<Header
					className={styles.header}
					style={{ marginTop: isNotificationBarVisible ? '50px' : '0px' }}
				>
					{
						pageTitle || parentTitle ?
							<div className={styles.breadcrumb}>
								<Button
									style={{ gridRow: '1 / 3' }}
									className={styles.backButton}
									onClick={() => backPageLink ? router.push(backPageLink) : router.back()}
								>
									<img className={styles.backIcon} src='/img/back.svg' alt='Back Icon' />
								</Button>
								<p
									className={styles.moduleName}
									onClick={parentLink ? () => router.push(parentLink) : null}
								>
									{pageTitle || parentTitle}
								</p>
								<p className={styles.moduleNameSmall}>
									{pageTitle || parentTitle}
									{
										(pageChildTitle || childTitle) &&
										<span className={styles.pageName}> <span className={styles.bullet}>&nbsp;•&nbsp;</span> {pageChildTitle || childTitle}</span>
									}
								</p>
							</div> :
							<div className={styles.navStart}>
								{
									<Link href='/'>
										<a>
											{
												isNotEmpty(logoUrl) && role !== claimsRoles.ADMIN ?
													<img
														className={styles.customLogo}
														src={logoUrl}
														alt='Nuport Logo'
														onClick={() => router.push('/')}
													/> :
													<img
														className={styles.logo}
														src='/img/logo-black.svg'
														alt='Nuport Logo'
														onClick={() => router.push('/')}
													/>
											}
										</a>
									</Link>
								}
								{activeSearchAndFilter}
							</div>
					}
					{
						role !== claimsRoles.ADMIN &&
						<div className={styles.navMiddle}>
							{
								modifiedNavLinks.map(({ title, path }) => {
									return (
										<Link key={path} href={path}>
											<a
												className={`${styles.navLink} ${path === pathname && styles.navLinkActive}`}
												onClick={() => {
													if (path === paths.SALES_ORDERS) {
														dispatch(selectSalesOrderIds([], []))
													}
													clearSearchParams()
												}}
											>
												{title}
											</a>
										</Link>
									)
								})
							}
						</div>
					}
					<div className={styles.navEnd} id='nav'>
						{
							role !== claimsRoles.ADMIN && defaultApp && !isECommerce &&
							<div className={styles.apps}>
								<Tooltip
									title={defaultApp.tooltip}
								>
									<div className={styles.currentApp}>
										<img src={defaultApp.icon.src} alt={defaultApp.icon.alt} />
									</div>
								</Tooltip>
								<Apps
									onAppSelected={onAppSelected}
								/>
							</div>
						}
						{
							isECommerce && role !== claimsRoles.ADMIN && role &&
							<Link href={'/release-notes'}>
								<a className={styles.releaseBadge}>
								Release Notes
									<released-badge
										channel-id='e250e2dc-1ca2-4ca7-8ff9-c7bb285afadf'
									/>
								</a>
							</Link>
						}
						{
							companyDetails?.isInsightsEnabled && permissions?.viewInsights && role !== claimsRoles.ADMIN && role &&
							<Link href={paths.INSIGHTS}>
								<div className={styles.insightIconContainer}>
									<img
										className={styles.insightIcon}
										src='/img/menu/light-bulb.svg'
									/>
									{
										newInsights &&
										<div className={styles.insightIndicator} />
									}
								</div>
							</Link>
						}
						{
							role === claimsRoles.USER || role === claimsRoles.OWNER ?
								<Dropdown
									overlay={<NotificationsTray />}
									trigger={['click']}
									getPopupContainer={() => document.getElementById('nav')}
								>
									<div className={styles.notificationContainer}>
										<img
											className={styles.notificationIcon}
											src='/img/bell.svg'
										/>
										{
											unreadCount > 0 &&
											<div className={styles.notificationIndicator} />
										}
									</div>
								</Dropdown> : null
						}
						<ProfileMenu
							logout={logout}
						/>
					</div>
				</Header>

				{/* Page component */}
				<Content
					className={styles.content}
					style={{
						padding: fullPage ? '0 0 0 88px' : '0 24px 0 96px',
						marginTop: isNotificationBarVisible ? '114px' : '64px'
					}}
				>
					{children}
				</Content>
			</Layout>
			<Sidebar menuItems={modifiedMenuItems} />
		</div>
	)
}

export default withAuth({
	onUnauth: AuthAction.REDIRECT_TO_LOGIN,
	authPageURL: () => {
		const { search, pathname, hash } = window.location
		if (!search.includes('customerPhone')) {
			return '/login'
		}
		const destination =
			`${`${pathname}${search}`.includes('/login?destination=') ? '' : '/login?destination='}${pathname.replace('/', '')}${search}${hash}`
		return destination
	}
})(Page)
